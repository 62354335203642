import {reactive, toRefs, computed,defineComponent,onBeforeMount,onMounted,getCurrentInstance,nextTick} from 'vue';
import CxGghCardUtil,{ICxGghCardDataObj} from './cxGghCardUtil';
export default defineComponent({
    name:'CxGghCard',
    title:'车型公告号',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:ICxGghCardDataObj=reactive<ICxGghCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                modelPath: utils.OrderProviderApi.buildUrl('/cxGgh')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                brandId: [utils.UtilPub.commonValidRule(proxy.$t('请选择品牌'))],
                cxId: [utils.UtilPub.commonValidRule(proxy.$t('cxGgh.form.cxId_rule'))],
                name: [utils.UtilPub.commonValidRule(proxy.$t('cxGgh.form.name_rule'))]
            },
            otherParams:{
                disableEdit:false,//车型公告号只要新增之后，就不能修改头部的基础信息了，只能修改车型公告号的配置明细信息
                countryData:[],//国家下拉数据集
                brandData:[],//品牌下拉数据集
                cxData:[],//车型下拉数据集
                cpmData:[],//成品码下拉数据集
                detailInfos:[],//配置详情数据集
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new CxGghCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'brandId'==params.comboId){
                    return dataObj.otherParams.brandData
                }
                if(params && 'cxId'==params.comboId){
                    return dataObj.otherParams.cxData
                }
                if(params && 'countryId'==params.comboId){
                    return dataObj.otherParams.countryData;
                }
                if(params && 'cpmId'==params.comboId){
                    return dataObj.otherParams.cpmData;
                }
                if(params && 'property'==params.comboId){
                    return [{value:0,label:'CBU'},{value:1,label:'CKD'},{value:2,label:'SKD'}];
                }
            }
        })

        //---------------------------selectOnchange--------------------------
        const selectOnChange=async (newValue:string,selectId:string)=>{
            if('brandId'==selectId){
                dataObj.form.cxId='';
                dataObj.otherParams.cxData=[];
                dataObj.otherParams.countryData=[];
                dataObj.otherParams.detailInfos=[];
                addConfig();
                if(newValue)dataObj.otherParams.cxData=await utils.OrderProviderApi.getAllCx({params:{brandId:newValue}});
            }
            if('cxId'==selectId){
                dataObj.otherParams.detailInfos=[];
                dataObj.otherParams.countryData=await utils.OrderProviderApi.getCxCountry({params:{cxId:newValue}});
                dataObj.otherParams.cpmData=await utils.OrderProviderApi.getCpmByBrandAndCx({params:{brandId:dataObj.form.brandId,cxId:newValue}});
                dataObj.otherParams.detailInfos=[];
                addConfig();
            }
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            dataObj.otherParams.detailInfos=res.data.detailInfos;
            dataObj.otherParams.detailInfos.forEach((item:any)=>{//国家和成品码多选，需要把后台的字符串转换为JSON
                if(item.countryId)item.countryId=JSON.parse(item.countryId);
                if(item.cpmId)item.cpmId=JSON.parse(item.cpmId);
            })
            if('/load'==addOrLoad){//编辑的时候，不会触发车型下拉改变事件，所以需要在这里把国家和成品码下拉数据构造好
                dataObj.otherParams.countryData=await utils.OrderProviderApi.getCxCountry({params:{cxId:res.data.cxId}});
                dataObj.otherParams.cpmData=await utils.OrderProviderApi.getCpmByBrandAndCx({params:{brandId:res.data.brandId,cxId:res.data.cxId}});
                dataObj.otherParams.disableEdit=true;
            }else{
                addConfig();
            }
        }

        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            //验证明细表格数据是否合法
            dataObj.form.detailInfos=dataObj.otherParams.detailInfos;
            for(let i=0;i<dataObj.form.detailInfos.length;i++){
                let item=dataObj.form.detailInfos[i];
                if(!item.name || !item.countryId || item.countryId.length==0 || !item.cpmId || item.cpmId.length==0 || !item.enDesc || !item.zhDesc){
                    proxy.$message({type:'warning',message:'配置'+(i<9?'0'+(i+1):(i+1))+'信息不完整'});
                    return false;
                }
            }
            return true;
        }

        //增加配置
        const addConfig=()=>{
            if(dataObj.otherParams.detailInfos.length>99){
                proxy.$message('最多可以增加99个配置');
                return;
            }
            dataObj.otherParams.detailInfos.push({id:'',name:'',property:0,countryId:'',cpmId:'',enDesc:'',zhDesc:''})
        }
        //下架配置
        const changeStatusConfig=async (index:number,status:number)=>{
            if(dataObj.otherParams.detailInfos[index].id){
                let res=await utils.OrderProviderApi.changeStatusConfig({params:{id:dataObj.otherParams.detailInfos[index].id,status:status}});
                if(res.result){
                    utils.Tools.success();
                    if(status==0){
                        dataObj.otherParams.detailInfos[index].flag=1;
                    }else{
                        dataObj.otherParams.detailInfos[index].flag=0;
                    }
                    await proxy.engine.engineUtil.doAddOrLoad(dataObj.form.id,'card');
                }
            }
        }
        //删除配置(目前只有新增才能删除，一旦保存了就不能删除了)
        const deleteConfig=async (index:number)=>{
            if(dataObj.otherParams.detailInfos.length==1){
                proxy.$message({type:'warning',message:'至少要保留一个配置明细'});
                return false;
            }
            if(dataObj.otherParams.detailInfos[index].id){//目前永远也执行不到这里，因为页面判断了编辑的时候不允许删除
                let res=await utils.OrderProviderApi.deleteConfig({params:{id:dataObj.otherParams.detailInfos[index].id}});
                if(res.result){
                    utils.Tools.success();
                    dataObj.otherParams.detailInfos.splice(index,1);
                }
            }else{
                dataObj.otherParams.detailInfos.splice(index,1);
            }
        }
        return{
            ...toRefs(dataObj),proxy,comboSelect,selectOnChange,beforeOpen,beforeSaveHandler,
            addConfig,changeStatusConfig,deleteConfig
        }
    }
});